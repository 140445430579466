import React from "react"

// Features
import Layout from "../features/Layout"
import SEO from "../features/Seo"

// Primitives
import { TermsOfService } from "shared-ui"

const TermsPage = () => {
  const termsPageData = {
    noSupporters: true,
    footer: {
      download: false,
    },
  }

  return (
    <Layout pageData={termsPageData}>
      <SEO title="Terms of Service" />
      <TermsOfService />
    </Layout>
  )
}

export default TermsPage
